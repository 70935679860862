<template>
    <main-layout itemMenuActive="32" tituloMenu="Condensado Onboarding Digital">
        <div class="container">
            <br />
            <form v-on:submit.prevent="handleSubmit">
                <div class="columns is-mobile is-multiline is-centered">
                    <div class="column is-12">
                        <div class="card">
                            <div class="card-content">
                                <b-field grouped label="Condensado de Onboarding Digital">
                                </b-field>
                                <b-field label="Fecha">
                                    <!--<b-datepicker type="month" placeholder="" icon="calendar-today" trap-focus
                                        v-model="fecha">
                                    </b-datepicker>-->
                                    <b-datepicker placeholder="" icon="calendar-today" trap-focus
                                        v-model="fecha" range :max-date="maxDate" :min-date="minDate">
                                    </b-datepicker>
                                    <b-button native-type="submit" type="is-info"
                                        style="margin-left: 0.5rem">Consultar</b-button>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <div class="columns is-mobile is-multiline is-centered">
                <div class="column is-6" v-if="series.length > 0">
                    <div class="card">
                        <div class="card-content">
                            <TableCard messageEmpty="Sin datos para procesar." :columns="columns" :info="dataTable"
                                detailKey="counter" :tableConfig="tableConfig" :haveFilter="false"></TableCard>
                        </div>
                    </div>
                </div>
                <div class="column is-6" v-if="series.length > 0">
                    <div class="card">
                        <div class="card-content">
                            <span>Transacciones Correctas VS Fallidas</span>
                            <div id="chart">
                                <apexchart type="pie" width="380" :options="chartOptions" :series="series"></apexchart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!---->
            <div class="columns is-mobile is-multiline is-centered">
                <div class="column is-6" v-if="seriesUsuarios.length > 0">
                    <div class="card">
                        <div class="card-content">
                            <TableCard messageEmpty="Sin datos para procesar." :columns="columns" :info="dataTableUsuarios"
                                detailKey="counter" :tableConfig="tableConfig" :haveFilter="false"></TableCard>
                        </div>
                    </div>
                </div>
                <div class="column is-6" v-if="seriesUsuarios.length > 0">
                    <div class="card">
                        <div class="card-content">
                            <span>Transacciones Correctas VS Fallidas por Usuario</span>
                            <div id="chart">
                                <apexchart type="pie" width="380" :options="chartOptionsUser" :series="seriesUsuarios"></apexchart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!---->
            <div class="columns is-mobile is-multiline is-centered">
                <div class="column is-12" v-if="seriesErrores.length > 0">
                    <div class="card">
                        <div class="card-content">
                            <span>Errores en Transacciones Fallidas </span>
                            <div id="chart">
                                <apexchart type="pie" width="800" :options="chartOptionsErrores" :series="seriesErrores"></apexchart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column is-12" v-if="seriesAbandonos.length > 0">
                    <div class="card">
                        <div class="card-content">
                            <span>Respuesta en Transacciones Abandonadas </span>
                            <div id="chart">
                                <apexchart type="pie" width="650" :options="chartOptionsAbandonos" :series="seriesAbandonos"></apexchart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!--<div class="columns is-mobile is-multiline is-centered">
                <div class="column is-12" v-if="seriesErrores.length > 0">
                    <div class="card">
                        <div class="card-content">
                            <span>Historico de Eventos en Transaccion de Onboarding Digital </span>
                            <div id="chart">
                                <apexchart type="bar" height="400" :options="chartStackOptions" :series="seriesStack"></apexchart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>-->
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
        </div>
    </main-layout>
</template>
<script>
import MainLayout from "@/components/Layout/MainLayout";
import { mapMutations, mapActions, mapState } from "vuex";
import apexchart from "vue-apexcharts";
//import { formatData } from "../../assets/config/utilities";
import { COLUMNS_CONSOLIDADO } from "../../assets/config/constants/TableConstants";
import {VU_SOP_ERROR_LABELS,VU_SOP_ESTADOS} from "../../assets/config/constants/GeneralConstants";
var numeral = require("numeral");
export default {
    name: "RLogin",
    components: {
        MainLayout, apexchart,
        TableCard: () => import("../../components/cards/TableCard")
    },
    data() {
        const today = new Date()
        return {
            seriesUsuarios: [],
            series: [],
            isLoading: false,
            locale: "en-CA",
            maxDate: new Date(),
            minDate: new Date(today.getFullYear()-1, today.getMonth(), today.getDay()),
            isCardModalActive: false,
            fecha: [],
            dataTable: [],
            dataTableUsuarios: [],
            tableConfig: {
                isPaginated: true,
                isPaginationRounded: true,
                perPage: 10,
                backendSorting: true,
                isDetailed: false,
                isPaginatedInput: true,
            },
            chartOptions: {
                chart: {
                    width: 380,
                    type: "pie",
                },
                labels: ["Correctas", "Fallidas", "Abandonadas"],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
            },
            chartOptionsUser: {
                chart: {
                    width: 380,
                    type: "pie",
                },
                labels: ["Correctas", "Fallidas"],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
            },
            seriesAbandonos: [],
            seriesErrores: [],
            chartOptionsErrores: {
                chart: {
                    width: 380,
                    type: "pie",
                },
                labels: [],
                legend: {
                    width: 550,
                    position: "right",
                    offsetY: 0,
                    fontSize: "11px",
                    fontWeight: 400,
                    markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 0,
                    fillColors: undefined,
                    radius: 12,
                    offsetX: 0,
                    offsetY: 0,
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
            },
            chartOptionsAbandonos: {
                chart: {
                    width: 380,
                    type: "pie",
                },
                labels: [],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
            },
            //Configuracion grafica eventos y estados
            seriesStack: [{ data: [] }],
            chartStackOptions: {
                chart: {
                type: "bar",
                height: 880,
                },
                xaxis: {
                type: "category",
                group: {
                    style: {
                    fontSize: "13px",
                    fontWeight: 700,
                    },
                    groups: [],
                },
                labels: {
                    show: true,
                    trim: true,
                    //rotate: -90,
                    //maxHeight: 120,
                    style: {
                    //colors: [],
                    fontSize: "8px",
                    fontWeight: 400,
                    //cssClass: "apexcharts-xaxis-label",
                    },
                },
                },
            },
            columns: COLUMNS_CONSOLIDADO
        };
    },
    computed: {
        ...mapState(["_HOST_SERVE", "_PERFIL_MODULO_"]),
    },
    mounted() {
        this.$emit("tituloTransaccion", "Condensado Autenticación");
        this.nombreTituloMenu("Condensado Autenticación");
    },
    methods: {
        handleSubmit() {
            this.series.length = 0;
            this.seriesUsuarios.length = 0;
            this.seriesErrores.length = 0;
            this.chartOptionsErrores.labels.length = 0;
            this.seriesAbandonos.length = 0;
            this.chartOptionsAbandonos.labels.length = 0;
            this.seriesStack.length = 0;
            this.chartStackOptions.xaxis.group.groups.length = 0;
            this.isLoading = true;
            const options = {
                method: "post",
                uri: "boards/sopCondensado",
                data: {
                    FechaInicial: this.fecha[0].length === 0 ? new Date() : this.fecha[0],
                    FechaFinal: this.fecha[1].length === 0 ? new Date() : this.fecha[1],
                },
            };
            this._axios(options)
                .then((response) => {
                    this.dataTable = [
                        { 'nombre': 'TX Onboarding Exitosas', 'cantidad': (response.data.cant_exitosas).toLocaleString('eS-CO')},
                        { 'nombre': 'TX Onboarding Abandonadas', 'cantidad': (response.data.cant_abandonos).toLocaleString('eS-CO') },
                        { 'nombre': 'TX Onboarding Fallidas', 'cantidad': (response.data.cant_fallidas).toLocaleString('eS-CO') },
                        { 'nombre': 'TX Total', 'cantidad': (response.data.cant_fallidas + response.data.cant_exitosas + response.data.cant_abandonos).toLocaleString('eS-CO') },
                        { 'nombre': 'Porcentaje de Efectividad', 'cantidad': this.cantidad( response.data.cant_exitosas, (response.data.cant_fallidas + response.data.cant_exitosas+response.data.cant_abandonos)) + "%"}
                    ];
                    if(response.data.cant_exitosas > 0 || response.data.cant_fallidas > 0){
                        this.series.push(response.data.cant_exitosas);
                        this.series.push(response.data.cant_fallidas);
                        this.series.push(response.data.cant_abandonos);
                    }

                    this.dataTableUsuarios = [
                        { 'nombre': 'Cantidad Usuarios Unicos Exitosos', 'cantidad': (response.data.cant_exitosas_user).toLocaleString('eS-CO')},
                        { 'nombre': 'Cantidad Usuarios Unicos Fallidos', 'cantidad': (response.data.cant_total_user-response.data.cant_exitosas_user).toLocaleString('eS-CO')},
                        { 'nombre': 'Cantidad Usuarios Unicos Totales', 'cantidad': (response.data.cant_total_user).toLocaleString('eS-CO') },
                        { 'nombre': 'Tasa de Conversión', 'cantidad': this.cantidad( response.data.cant_exitosas_user, response.data.cant_total_user) + "%"}
                    ];
                    if(response.data.cant_exitosas_user > 0 || response.data.cant_total_user > 0){
                        this.seriesUsuarios.push(response.data.cant_exitosas_user);
                        this.seriesUsuarios.push((response.data.cant_total_user-response.data.cant_exitosas_user));
                    }
                    response.data.datos.forEach((item) => {
                        this.seriesErrores.push(item.cantidad);
                        if (VU_SOP_ERROR_LABELS.get(item.error) === undefined) {
                            this.chartOptionsErrores.labels.push(item.error);
                        }else{
                            this.chartOptionsErrores.labels.push(
                            VU_SOP_ERROR_LABELS.get(item.error)
                            );
                        }
                    })
                    response.data.abandonados.forEach((item) => {
                        this.seriesAbandonos.push(item.cantidad);
                        if (VU_SOP_ERROR_LABELS.get(item.error) === undefined) {
                            this.chartOptionsAbandonos.labels.push(item.error);
                        }else{
                        this.chartOptionsAbandonos.labels.push(
                            VU_SOP_ERROR_LABELS.get(item.error)
                            );
                        }
                    })
                    var arr = [];
                    response.data.estados.forEach((element) => {
                        arr.push(element.estado);
                        this.seriesStack.push({ x: element.evento, y: element.cantidad });
                    })
                    const countUnique = (arr) => {
                        const counts = [];
                        for (var i = 0; i < arr.length; i++) {
                        counts[arr[i]] = 1 + (counts[arr[i]] || 0);
                        }
                        return counts;
                    };
                    var counts = countUnique(arr);
                    var arreglo = [];
                    for (var clave in counts) {
                        arreglo.push({ title: clave, cols: counts[clave] });
                    }
                    console.log(arreglo);
                    this.seriesStack = [
                        {
                            name: 'Cantidad',
                            data: this.seriesStack,
                        },
                    ];
                    
                    arreglo.forEach(element => {
                        element.title = VU_SOP_ESTADOS.get(element.title);
                    });
                    for (let j = 0; j < arreglo.length; j++) {
                        this.chartStackOptions.xaxis.group.groups.push(arreglo[j]);
                    }

                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                })
                .finally(() => this.cancelarLoading());
        },
        cancelarLoading() {
            setTimeout(() => {
                this.isLoading = false;
            }, 1500);
        },
        cantidad: function (cantidad1, cantidad2) {
            return numeral((cantidad1 / cantidad2) * 100).format("0,0.00");
        },
        ...mapMutations(["nombreTituloMenu"]),
        ...mapActions(["_axios"]),
    }
}
</script>
